export default {
  state: {
    show: true,
    theme: 'light',
    bgColor: '#ffffff00',
    shadow: false,
  },
  mutations: {
    setNavShow (state, payload) {
      state.show = payload;
    },
    setNavTheme (state, payload) {
      state.theme = payload;
    },
    setNavBgColor (state, payload) {
      state.bgColor = payload;
    },
    setNavShadow (state, payload) {
      state.shadow = payload;
    },
  },
};
