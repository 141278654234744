export default {
  state: {
    scrollTop: 0,
    infiniteScrollDistance: 0,
  },
  mutations: {
    setScrollTop (state, payload) {
      state.scrollTop = payload;
    },
    forceScroll (state, payload) {
      if (typeof payload === 'number') {
        document.getElementById('app').scrollTop = payload;
        state.scrollTop = payload;
      }
      if (typeof payload === 'object') {
        document.getElementById('app').scrollTo(payload);
        state.scrollTop = payload.top;
      }
    },
    setInfiniteScrollDistance (state, payload) {
      state.infiniteScrollDistance = payload;
    },
  },
};
